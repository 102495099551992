<template>
    <div class="testimonial testimonial_style--1 h-100">
        <div class="content d-flex flex-row flex-wrap h-100">
          <ul>
            <li v-for="(company, i) in companies" :key="i">
              <h5 class="heading heading-h5 mb-0">
                <p>{{ company }}</p>
              </h5>
            </li>
          </ul>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['companies']
    };
</script>

<style scoped lang="scss">

</style>
